var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vx-row" },
        [
          _c(
            "vs-row",
            {
              staticClass: "mb-base",
              attrs: { "vs-type": "flex", "vs-justify": "center" }
            },
            [
              _c("img", {
                staticClass: "imag-blur",
                staticStyle: { height: "90px" },
                attrs: {
                  src: require("../../assets/images/logo/BlockPay.png"),
                  alt: ""
                }
              })
            ]
          ),
          _vm._m(0),
          _c(
            "vs-row",
            {
              staticClass: "mb-base",
              attrs: { "vs-type": "flex", "vs-justify": "center" }
            },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2"
                  }
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: {
                        color: "rgb(27,48,83)",
                        "icon-pack": "feather",
                        icon: "icon-log-in",
                        size: "large"
                      },
                      on: { click: _vm.goBlockInvest }
                    },
                    [_vm._v("Ir")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "vx-col w-full" }, [
            _c(
              "div",
              { staticClass: "vx-row" },
              _vm._l(_vm.accountOption, function(account, indextr) {
                return _c(
                  "div",
                  { key: indextr, staticClass: "vx-col w-full md:w-1/5 mb-4" },
                  [
                    _c(
                      "vx-card",
                      { staticClass: "text-center cursor-pointer" },
                      [
                        account.typeAccountId ==
                        _vm.database.typeAccount.MainAccount
                          ? _c("vs-avatar", {
                              attrs: {
                                icon: "account_balance",
                                svgClasses: "h-10 w-10",
                                size: "large",
                                color: "rgb(23, 137, 186)"
                              }
                            })
                          : account.typeAccountId ==
                            _vm.database.typeAccount.PositiveBalance
                          ? _c("vs-avatar", {
                              attrs: {
                                icon: "beenhere",
                                size: "large",
                                color: "rgb(23, 137, 186)"
                              }
                            })
                          : account.typeAccountId ==
                            _vm.database.typeAccount.Saving
                          ? _c("vs-avatar", {
                              attrs: {
                                icon: "flight_takeoff",
                                size: "large",
                                color: "rgb(23, 137, 186)"
                              }
                            })
                          : _c("vs-avatar", {
                              attrs: {
                                icon: "account_balance_wallet",
                                size: "large",
                                color: "rgb(23, 137, 186)"
                              }
                            }),
                        _c("h4", { staticClass: "mb-2 text-primary" }, [
                          _c("strong", [_vm._v(_vm._s(account.name))])
                        ]),
                        _c("h1", { staticClass: "text-success" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(account.currencyCode) +
                                " " +
                                _vm._s(account.balance)
                            )
                          ])
                        ]),
                        _c("p", { staticClass: "mb-2" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(_vm.resources.Type.i18n) ||
                                  _vm.resources.Type.name
                              ) +
                              ":\n              " +
                              _vm._s(account.typeAccountName) +
                              "\n            "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c(
                "vx-card",
                { staticClass: "mb-2" },
                [
                  _c(
                    "vs-row",
                    { attrs: { "vs-type": "flex", "vs-justify": "center" } },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "2"
                          }
                        },
                        [
                          _c("vs-button", {
                            attrs: {
                              radius: "",
                              color: "warning",
                              "icon-pack": "material-icons",
                              icon: "edit",
                              size: "large"
                            },
                            on: {
                              click: function($event) {
                                _vm.popupEdit = true
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "2"
                          }
                        },
                        [
                          _c("vs-button", {
                            attrs: {
                              radius: "",
                              color: "rgb(27,48,83)",
                              "icon-pack": "feather",
                              icon: "icon-log-in",
                              size: "large"
                            },
                            on: { click: _vm.goBlockInvest }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "vs-row",
                    {
                      staticClass: "mb-4",
                      attrs: { "vs-type": "flex", "vs-justify": "center" }
                    },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "2"
                          }
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(_vm.resources.Update.i18n) ||
                                    _vm.$t(_vm.resources.Update.name)
                                ) +
                                "\n            "
                            )
                          ])
                        ]
                      ),
                      _vm.itaStatus == "1"
                        ? _c(
                            "vs-col",
                            {
                              attrs: {
                                "vs-type": "flex",
                                "vs-justify": "center",
                                "vs-align": "center",
                                "vs-w": "2"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "imag-blur",
                                staticStyle: {
                                  height: "50px",
                                  cursor: "pointer"
                                },
                                attrs: {
                                  src: require("../../assets/images/logo/BlockInvest.png"),
                                  alt: ""
                                },
                                on: { click: _vm.goBlockInvest }
                              })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("h4", { staticClass: "mb-4" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(_vm.resources.Filters.i18n) ||
                            _vm.resources.Filters.name
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("div", { staticClass: "vx-row" }, [
                    _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.AccountNumber.i18n) ||
                                  _vm.resources.Type.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            attrs: {
                              clearable: true,
                              options: _vm.accountOption,
                              reduce: function(item) {
                                return item.id
                              },
                              label: "nameWithNumber",
                              hint: "" + _vm.accountId
                            },
                            model: {
                              value: _vm.accountId,
                              callback: function($$v) {
                                _vm.accountId = $$v
                              },
                              expression: "accountId"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/6 mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(_vm.resources.StartDate.i18n) ||
                                  _vm.resources.StartDate.name
                              ) +
                              "\n            "
                          )
                        ]),
                        _c("datepicker", {
                          attrs: {
                            language: _vm.languages[_vm.language],
                            format: "d MMMM yyyy"
                          },
                          model: {
                            value: _vm.start_date,
                            callback: function($$v) {
                              _vm.start_date = $$v
                            },
                            expression: "start_date"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/6 mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.EndDate.i18n) ||
                                _vm.resources.EndDate.name
                            )
                          )
                        ]),
                        _c("datepicker", {
                          attrs: {
                            language: _vm.languages[_vm.language],
                            format: "d MMMM yyyy"
                          },
                          model: {
                            value: _vm.end_date,
                            callback: function($$v) {
                              _vm.end_date = $$v
                            },
                            expression: "end_date"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Type.i18n) ||
                                  _vm.resources.Type.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            attrs: {
                              clearable: true,
                              options: _vm.typeOption,
                              reduce: function(item) {
                                return item.id
                              },
                              label: "name"
                            },
                            model: {
                              value: _vm.type,
                              callback: function($$v) {
                                _vm.type = $$v
                              },
                              expression: "type"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/6 mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-hash",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                              _vm.resources.TransactionNumber.name
                          },
                          model: {
                            value: _vm.transactionNumber,
                            callback: function($$v) {
                              _vm.transactionNumber = $$v
                            },
                            expression: "transactionNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col mb-2 mt-5" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { color: "primary", type: "filled" },
                            on: {
                              click: function($event) {
                                return _vm.getActivities()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Search.i18n) ||
                                  _vm.resources.Search.name
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "ag-grid-demo" } },
        [
          _c(
            "vx-card",
            {
              attrs: {
                title:
                  _vm.$t(_vm.resources.Activities.i18n) ||
                  _vm.resources.Activities.name
              }
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-between items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                    },
                    [
                      _c(
                        "vs-dropdown",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { "vs-trigger-click": "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentPage * _vm.paginationPageSize -
                                      (_vm.paginationPageSize - 1)
                                  ) +
                                    "\n                -\n                " +
                                    _vm._s(
                                      _vm.activitiesList.length -
                                        _vm.currentPage *
                                          _vm.paginationPageSize >
                                        0
                                        ? _vm.currentPage *
                                            _vm.paginationPageSize
                                        : _vm.activitiesList.length
                                    ) +
                                    "\n                of " +
                                    _vm._s(_vm.activitiesList.length)
                                )
                              ]),
                              _c("feather-icon", {
                                attrs: {
                                  icon: "ChevronDownIcon",
                                  svgClasses: "h-4 w-4"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "vs-dropdown-menu",
                            [
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        20
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("20")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        50
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("50")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        100
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("100")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        150
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("150")])]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                    },
                    [
                      _c("vs-input", {
                        staticClass: "mb-4 md:mb-0 mr-4",
                        attrs: {
                          placeholder:
                            _vm.$t(_vm.resources.Search.i18n) ||
                            _vm.resources.Search.name
                        },
                        on: { input: _vm.updateSearchQuery },
                        model: {
                          value: _vm.searchQuery,
                          callback: function($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery"
                        }
                      }),
                      _c(
                        "vs-button",
                        {
                          staticClass: "mb-4 md:mb-0",
                          on: {
                            click: function($event) {
                              return _vm.gridApi.exportDataAsCsv()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.ExportAs.i18n) ||
                                  _vm.resources.ExportAs.name
                              ) +
                              "\n            CVS\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("ag-grid-vue", {
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                attrs: {
                  gridOptions: _vm.gridOptions,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.activitiesList,
                  rowSelection: "single",
                  colResizeDefault: "shift",
                  animateRows: true,
                  floatingFilter: true,
                  pagination: true,
                  paginationPageSize: _vm.paginationPageSize,
                  suppressPaginationPanel: true
                },
                on: { "row-selected": _vm.onRowSelected }
              }),
              _c("vs-pagination", {
                attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Update.i18n) || _vm.resources.Update.i18n,
            active: _vm.popupEdit
          },
          on: {
            "update:active": function($event) {
              _vm.popupEdit = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full md:w-1/2 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.AccountNumber.i18n) ||
                          _vm.resources.Type.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: false,
                      options: _vm.accountOption,
                      reduce: function(item) {
                        return item.id
                      },
                      label: "nameWithNumber",
                      hint: "" + _vm.accountIdEdit,
                      name: "accountName"
                    },
                    on: {
                      input: function($event) {
                        return _vm.getAccountName("" + _vm.accountIdEdit)
                      }
                    },
                    model: {
                      value: _vm.accountIdEdit,
                      callback: function($$v) {
                        _vm.accountIdEdit = $$v
                      },
                      expression: "accountIdEdit"
                    }
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("accountName"),
                          expression: "errors.has('accountName')"
                        }
                      ],
                      staticClass: "text-danger text-sm"
                    },
                    [_vm._v(_vm._s(_vm.errors.first("accountName")))]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: false, regex: /^([a-zA-Z0-9 ]+)$/ },
                        expression:
                          "{ required: false, regex: /^([a-zA-Z0-9 ]+)$/ }"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-user",
                      label:
                        _vm.$t(_vm.resources.Name.i18n) ||
                        _vm.resources.Name.name,
                      maxlength: "20",
                      name: "name"
                    },
                    model: {
                      value: _vm.accountName,
                      callback: function($$v) {
                        _vm.accountName = $$v
                      },
                      expression: "accountName"
                    }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("name"),
                      expression: "errors.has('name')"
                    }
                  ],
                  staticClass: "text-danger text-sm"
                },
                [_vm._v(_vm._s(_vm.errors.first("name")))]
              )
            ]),
            _c(
              "div",
              {
                staticClass: "vx-col mb-base",
                staticStyle: { "text-align": "right" }
              },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.updateAccount()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Description.i18n) ||
              _vm.resources.Description.i18n,
            active: _vm.popupDetail
          },
          on: {
            "update:active": function($event) {
              _vm.popupDetail = $event
            }
          }
        },
        [
          _c(
            "h1",
            { staticClass: "text-center text-primary font-bold mb-base" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(_vm.resources.Answer.i18n) ||
                      _vm.resources.Answer.i18n
                  ) +
                  ":\n    "
              )
            ]
          ),
          _c("p", { staticClass: "text-center mb-base" }, [
            _vm._v(_vm._s(_vm.description))
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Invest.i18n) ||
              _vm.$t(_vm.resources.Invest.name),
            active: _vm.popupInvest
          },
          on: {
            "update:active": function($event) {
              _vm.popupInvest = $event
            }
          }
        },
        [
          _c(
            "vs-row",
            {
              staticClass: "mb-base",
              attrs: { "vs-type": "flex", "vs-justify": "center" }
            },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center"
                  }
                },
                [
                  _c("img", {
                    staticClass: "imag-blur",
                    staticStyle: { height: "50px", cursor: "pointer" },
                    attrs: {
                      src: require("../../assets/images/logo/BlockInvest.png"),
                      alt: ""
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full md:w-1/2 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Origin.i18n) ||
                          _vm.resources.Origin.name
                      ) +
                        " -\n            " +
                        _vm._s(
                          _vm.$t(_vm.resources.AccountNumber.i18n) ||
                            _vm.resources.AccountNumber.name
                        )
                    )
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    attrs: {
                      clearable: true,
                      options: _vm.accountOption,
                      reduce: function(item) {
                        return item.id
                      },
                      label: "nameWithNumber",
                      name: "account"
                    },
                    model: {
                      value: _vm.accountIdInvest,
                      callback: function($$v) {
                        _vm.accountIdInvest = $$v
                      },
                      expression: "accountIdInvest"
                    }
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("account"),
                          expression: "errors.has('account')"
                        }
                      ],
                      staticClass: "text-danger text-sm"
                    },
                    [_vm._v(_vm._s(_vm.errors.first("account")))]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|decimal:2|min_value:25",
                        expression: "'required|decimal:2|min_value:25'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.Value.i18n) ||
                        _vm.resources.Value.name,
                      name: "amount"
                    },
                    model: {
                      value: _vm.amountInvest,
                      callback: function($$v) {
                        _vm.amountInvest = $$v
                      },
                      expression: "amountInvest"
                    }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("amount"),
                      expression: "errors.has('amount')"
                    }
                  ],
                  staticClass: "text-danger text-sm"
                },
                [_vm._v(_vm._s(_vm.errors.first("amount")))]
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Remark.i18n) ||
                        _vm.resources.Remark.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:5|max:500",
                      expression: "'required|min:5|max:500'"
                    }
                  ],
                  attrs: { name: "observation" },
                  model: {
                    value: _vm.observationInvest,
                    callback: function($$v) {
                      _vm.observationInvest = $$v
                    },
                    expression: "observationInvest"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("observation"),
                        expression: "errors.has('observation')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("observation")))]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "vx-col mb-2 mt-5",
                staticStyle: { "text-align": "right" }
              },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.validInvest()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(_vm.resources.Send.i18n) ||
                            _vm.resources.Send.name
                        ) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "text-center text-danger mb-4ccccc" }, [
      _vm._v(
        "Nos hemos renovado y te invitamos a hacer uso de nuestra nueva\n      plataforma de\n      "
      ),
      _c("strong", [_vm._v("Blockpay")]),
      _vm._v(
        " para realizar todos tus movimientos de una forma más rápida y segura. También, tendrás\n      integrado en un sólo lugar Block Invest.\n    "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }