<template>
  <div>
    <div class="vx-row">
      <vs-row vs-type="flex" vs-justify="center" class="mb-base">
        <img src="../../assets/images/logo/BlockPay.png" alt class="imag-blur" style="height: 90px" />
      </vs-row>
      <h4 class="text-center text-danger mb-4ccccc">Nos hemos renovado y te invitamos a hacer uso de nuestra nueva
        plataforma de
        <strong>Blockpay</strong> para realizar todos tus movimientos de una forma más rápida y segura. También, tendrás
        integrado en un sólo lugar Block Invest.
      </h4>
      <vs-row vs-type="flex" vs-justify="center" class="mb-base">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button color="rgb(27,48,83)" icon-pack="feather" icon="icon-log-in" size="large"
            @click="goBlockInvest">Ir</vs-button>
        </vs-col>
      </vs-row>
      <div class="vx-col w-full">
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/5 mb-4" :key="indextr" v-for="(account, indextr) in accountOption">
            <vx-card class="text-center cursor-pointer">
              <vs-avatar v-if="account.typeAccountId == database.typeAccount.MainAccount" icon="account_balance"
                svgClasses="h-10 w-10" size="large" color="rgb(23, 137, 186)" />
              <vs-avatar v-else-if="account.typeAccountId == database.typeAccount.PositiveBalance
                " icon="beenhere" size="large" color="rgb(23, 137, 186)" />
              <vs-avatar v-else-if="account.typeAccountId == database.typeAccount.Saving" icon="flight_takeoff"
                size="large" color="rgb(23, 137, 186)" />
              <vs-avatar v-else icon="account_balance_wallet" size="large" color="rgb(23, 137, 186)" />
              <h4 class="mb-2 text-primary">
                <strong>{{ account.name }}</strong>
              </h4>
              <h1 class="text-success">
                <strong>{{ account.currencyCode }} {{ account.balance }}</strong>
              </h1>
              <p class="mb-2">
                {{ $t(resources.Type.i18n) || resources.Type.name }}:
                {{ account.typeAccountName }}
              </p>
            </vx-card>
          </div>
          <!-- <div class="vx-col w-full md:w-1/5 mb-4">
            <vx-card class="text-center cursor-pointer">
              <vs-avatar icon="notification_important" size="large" color="rgb(23, 137, 186)" />
              <h4 class="mb-2 text-primary">
                <strong>{{
                  $t(resources.BalanceForInvesting.i18n) ||
                  resources.BalanceForInvesting.name
                }}</strong>
              </h4>
              <h1 class="text-success">
                <strong>USD {{ balancePending }}</strong>
              </h1>
              <p class="mb-2">Block Invest</p>
            </vx-card>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-4">
            <vx-card class="text-center cursor-pointer">
              <vs-avatar icon="show_chart" size="large" color="rgb(23, 137, 186)" />
              <h4 class="mb-2 text-primary">
                <strong>{{
                  $t(resources.Investment.i18n) || resources.Investment.name
                }}</strong>
              </h4>
              <h1 class="text-success">
                <strong>USD {{ investment }}</strong>
              </h1>
              <p class="mb-2">Block Invest</p>
            </vx-card>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-4">
            <vx-card class="text-center cursor-pointer">
              <vs-avatar icon="monetization_on" size="large" color="rgb(23, 137, 186)" />
              <h4 class="mb-2 text-primary">
                <strong>{{
                  $t(resources.Profitability.i18n) ||
                  resources.Profitability.name
                }}
                  ({{
                    $t(resources.Month.i18n) || resources.Month.name
                  }})</strong>
              </h4>
              <h1 class="text-success">
                <strong>USD {{ profitability }}</strong>
              </h1>
              <p class="mb-2">Block Invest</p>
            </vx-card>
          </div> -->
        </div>
      </div>
      <div class="vx-col w-full">
        <vx-card class="mb-2">
          <vs-row vs-type="flex" vs-justify="center">
            <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
              <vs-button radius color="primary" icon-pack="material-icons" icon="send" size="large"
                @click="$router.push('/blockPay/send')"></vs-button>
            </vs-col> -->
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
              <vs-button radius color="warning" icon-pack="material-icons" icon="edit" size="large"
                @click="popupEdit = true"></vs-button>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
              <vs-button radius color="rgb(27,48,83)" icon-pack="feather" icon="icon-log-in" size="large"
                @click="goBlockInvest"></vs-button>
            </vs-col>
          </vs-row>
          <vs-row vs-type="flex" vs-justify="center" class="mb-4">
            <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
              <p>
                {{
                  $t(resources.SendMoney.i18n) || $t(resources.SendMoney.name)
                }}
              </p>
            </vs-col> -->
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
              <p>
                {{ $t(resources.Update.i18n) || $t(resources.Update.name) }}
              </p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="itaStatus == '1'">
              <img src="../../assets/images/logo/BlockInvest.png" alt class="imag-blur"
                style="height: 50px; cursor: pointer" @click="goBlockInvest" />
            </vs-col>
          </vs-row>
          <h4 class="mb-4">
            {{ $t(resources.Filters.i18n) || resources.Filters.name }}
          </h4>
          <div class="vx-row">
            <div class="vx-col md:w-1/6 w-full mb-2">
              <div class="vx-col w-full">
                <label class="vs-input--label">{{
                  $t(resources.AccountNumber.i18n) || resources.Type.name
                }}</label>
                <v-select v-model="accountId" :clearable="true" :options="accountOption" :reduce="item => item.id"
                  label="nameWithNumber" :hint="`${accountId}`" />
              </div>
            </div>
            <div class="vx-col w-full md:w-1/6 mb-2">
              <label class="vs-input--label">
                {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
              </label>
              <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="start_date"></datepicker>
            </div>
            <div class="vx-col w-full md:w-1/6 mb-2">
              <label class="vs-input--label">{{
                $t(resources.EndDate.i18n) || resources.EndDate.name
              }}</label>
              <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="end_date"></datepicker>
            </div>
            <div class="vx-col w-full md:w-1/6 mb-2">
              <div class="vx-col w-full">
                <label class="vs-input--label">{{
                  $t(resources.Type.i18n) || resources.Type.name
                }}</label>
                <v-select v-model="type" :clearable="true" :options="typeOption" :reduce="item => item.id" label="name" />
              </div>
            </div>
            <div class="vx-col w-full md:w-1/6 mb-2">
              <vs-input class="w-full" icon-pack="feather" icon="icon-hash" icon-no-border :label="$t(resources.TransactionNumber.i18n) ||
                resources.TransactionNumber.name
                " v-model="transactionNumber" />
            </div>
            <div class="vx-col mb-2 mt-5">
              <vs-button color="primary" type="filled" @click="getActivities()">{{
                $t(resources.Search.i18n) || resources.Search.name
              }}</vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div id="ag-grid-demo">
      <vx-card :title="$t(resources.Activities.i18n) || resources.Activities.name">
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                  -
                  {{
                    activitiesList.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : activitiesList.length
                  }}
                  of {{ activitiesList.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name" />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
              CVS
            </vs-button>
          </div>
        </div>
        <ag-grid-vue :gridOptions="gridOptions" class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs" :defaultColDef="defaultColDef" :rowData="activitiesList" rowSelection="single"
          colResizeDefault="shift" :animateRows="true" :floatingFilter="true" :pagination="true"
          :paginationPageSize="paginationPageSize" :suppressPaginationPanel="true"
          @row-selected="onRowSelected"></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>
    <vs-popup :title="$t(resources.Update.i18n) || resources.Update.i18n" :active.sync="popupEdit">
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.AccountNumber.i18n) || resources.Type.name
            }}</label>
            <v-select v-model="accountIdEdit" :clearable="false" :options="accountOption" :reduce="item => item.id"
              label="nameWithNumber" v-on:input="getAccountName(`${accountIdEdit}`)" :hint="`${accountIdEdit}`"
              name="accountName" />
            <span class="text-danger text-sm" v-show="errors.has('accountName')">{{ errors.first("accountName") }}</span>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user"
              :label="$t(resources.Name.i18n) || resources.Name.name" v-model="accountName" maxlength="20"
              v-validate="{ required: false, regex: /^([a-zA-Z0-9 ]+)$/ }" name="name" />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
        <div class="vx-col mb-base" style="text-align: right">
          <vs-button color="primary" type="filled" @click="updateAccount()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup :title="$t(resources.Description.i18n) || resources.Description.i18n" :active.sync="popupDetail">
      <h1 class="text-center text-primary font-bold mb-base">
        {{ $t(resources.Answer.i18n) || resources.Answer.i18n }}:
      </h1>
      <p class="text-center mb-base">{{ description }}</p>
    </vs-popup>
    <vs-popup :title="$t(resources.Invest.i18n) || $t(resources.Invest.name)" :active.sync="popupInvest">
      <vs-row vs-type="flex" vs-justify="center" class="mb-base">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <img src="../../assets/images/logo/BlockInvest.png" alt class="imag-blur"
            style="height: 50px; cursor: pointer" />
        </vs-col>
      </vs-row>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{ $t(resources.Origin.i18n) || resources.Origin.name }} -
              {{
                $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
              }}</label>
            <v-select v-model="accountIdInvest" :clearable="true" :options="accountOption" :reduce="item => item.id"
              label="nameWithNumber" v-validate="'required'" name="account" />
            <span class="text-danger text-sm" v-show="errors.has('account')">{{
              errors.first("account")
            }}</span>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-dollar-sign" type="number"
              :label="$t(resources.Value.i18n) || resources.Value.name" v-model="amountInvest"
              v-validate="'required|decimal:2|min_value:25'" name="amount" />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('amount')">{{
            errors.first("amount")
          }}</span>
        </div>
        <div class="vx-col w-full">
          <label>{{
            $t(resources.Remark.i18n) || resources.Remark.name
          }}</label>
          <vs-textarea v-model="observationInvest" v-validate="'required|min:5|max:500'" name="observation" />
          <span class="text-danger text-sm" v-show="errors.has('observation')">{{ errors.first("observation") }}</span>
        </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click.prevent="validInvest()">
            {{ $t(resources.Send.i18n) || resources.Send.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import database from "@/assets/resources/enums/database.json";
import status from "@/assets/resources/enums/status.json";
import axios from "axios";
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import { Validator } from "vee-validate";
import StringCrypto from "string-crypto";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";

export default {
  components: {
    vSelect,
    AgGridVue,
    Validator,
    Datepicker
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      database: database,
      status: status,
      language: this.$i18n.locale,
      languages: lang,
      start_date: new Date().setDate(new Date().getDate() - 30),
      end_date: new Date(),
      type: "",
      typeOption: [],
      accountType: "",
      accountTypeOption: [],
      transactionNumber: "",
      popupEdit: false,
      popupDetail: false,
      popupInvest: false,
      itaStatus: localStorage.getItem("status"),
      contactId: JSON.parse(localStorage.getItem("userInfo")).id,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      accountIdEdit: "",
      accountName: "",
      accountId: "",
      accountOption: [],
      stageId: 23,
      stageList: [],
      popupActive: false,
      activitiesList: [],
      description: "",
      investment: 0,
      profitability: 0,
      balancePending: 0,
      amountInvest: 25.0,
      accountIdInvest: "",
      observationInvest: "",
      investId: "",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Status"),
          field: "stageName",
          width: 150
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "activityType",
          width: 220
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "value",
          width: 160,
          cellRenderer: function (event) {
            let object = "";
            if (event.node.data.value < 0) {
              object = `<span class='text-danger'>${event.node.data.value}</span>`;
            } else object = object = `<span>${event.node.data.value}</span>`;

            return object;
          }
        },
        {
          headerName: this.$i18n.t("TransactionNumber"),
          field: "transactionCode",
          width: 180
        },
        {
          headerName: this.$i18n.t("Description"),
          field: "observation",
          width: 450
        },
        {
          headerName: this.$i18n.t("ReferenceCode"),
          field: "referenceId"
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate"
        },
        {
          headerName: this.$i18n.t("PaymentDate"),
          field: "paymentDate"
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  async created() {
    this.$vs.loading();

    await this.getBlockInvest();
    await this.getActivityType();
    await this.getAccounts();
    await this.getActivities();

    this.$vs.loading.close();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    handleSearch(searching) {
      if (searching.length >= 3) {
        this.activitiesList = this.activitiesList.filter(
          x =>
            x.observation.toLowerCase().includes(searching.toLowerCase()) ||
            x.transactionCode.toLowerCase().includes(searching.toLowerCase()) ||
            x.typeActivityName.toLowerCase().includes(searching.toLowerCase())
        );
      }
    },

    goBlockInvest() {
      window.open("https://blockpay.visionbiz.net/");

      // const { encryptString } = new StringCrypto();
      // let _tokenVT = localStorage.getItem("tokenVT");
      // let _ita = encryptString(this.itaCode, "VLnGyPa9Ewtqj33");
      // let _token = encryptString(_tokenVT, "VLnGyPa9Ewtqj33");
      // let _url = `${process.env.VUE_APP_BI_FRONT}/${_ita}/${_token}`;
      // window.location.href = _url;
    },

    async cleanFields() {
      this.$vs.dialog({
        color: "warning",
        title: this.$i18n.t("Alert"),
        text: `Nos hemos renovado.`
      });
      // this.accountIdInvest = "";
      // this.amountInvest = 25.0;
      // this.observationInvest = "";
      // if (openPopUp) this.popupInvest = true;
      // else {
      //   this.popupInvest = false;
      //   await this.getAccounts();
      //   //await this.getBlockInvest();
      // }
    },

    validInvest() {
      this.$validator.validateAll().then(async result => {
        let _account = this.accountOption.find(
          x => x.id == this.accountIdInvest
        );
        let _amount = parseFloat(_account.balance);

        if (_amount < this.amountInvest) {
          this.popupInvest = false;
          this.$vs.dialog({
            color: "warning",
            title: this.$i18n.t("Alert"),
            text: this.$i18n
              .t("MsgNotBalance")
              .replace("{0}", _account.nameWithNumber)
              .replace("{1}", _amount)
          });
        } else if (result) {
          this.$vs.loading();

          await this.createActivity(false);
          await this.sendBlockInvest(false);
          await this.cleanFields(false);

          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async getBlockInvest() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_BLOCKINVEST}`,
        data: {
          token: process.env.VUE_APP_BI_TOKEN,
          accion: "balance",
          idcode: parseInt(this.itaCode)
        },
        headers: {
          "content-type": "application/json"
        }
      }).then(
        async result => {
          if (result.data != null && result.data.estatus) {
            this.balancePending = result.data.saldo;
            this.investment = result.data.inversion;
            this.profitability = result.data.rentabilidad;
            if (
              result.data.transferencias == null ||
              result.data.transferencias == undefined
            ) {
              this.amountInvest = 0;
              await this.sendBlockInvest(true);
              this.amountInvest = 25;
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async sendBlockInvest(isCreateAccount) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_BLOCKINVEST}`,
        data: {
          token: process.env.VUE_APP_BI_TOKEN,
          accion: "send",
          idcode: parseInt(this.itaCode),
          monto: parseFloat(this.amountInvest) ///.toFixed(2),
        },
        headers: {
          "content-type": "application/json"
        }
      }).then(
        async result => {
          if (result.data != null && result.data.estatus && !isCreateAccount) {
            this.$vs.dialog({
              color: "success",
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgInvest")
            });
          } else if (!isCreateAccount && result.data.transferencias == null) {
            await this.createActivity(true);
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotInvest")
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createActivity(cancel) {
      let _referenceId = "BLOCKINVEST";
      if (cancel)
        _referenceId = this.activitiesList.find(x => x.id == this.investId)
          .transactionCode;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Activity/Create`,
        data: {
          typeActivityId: this.database.typeActivity.TransferBlockInvest,
          accountId: this.accountIdInvest,
          stageId: this.status.payment.paid,
          transactionCode: "",
          value: cancel ? this.amountInvest : -this.amountInvest,
          referenceId: _referenceId,
          observation: cancel
            ? `DECLINADO: ${this.$i18n.t("MsgNotInvest")} ///// ${this.observationInvest
            }`
            : this.observationInvest,
          createdBy: this.emailSession,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            if (!cancel) {
              this.investId = result.data.data.id;
              await this.getAccounts();

              this.$vs.notify({
                title: this.$i18n.t("Success"),
                text: result.data.message,
                color: "success"
              });
            }
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAccounts() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT
          }Account/GetByContact/${this.$i18n.locale.toUpperCase()}/${this.contactId
          }`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        async result => {
          if (result.data.success && result.data.resultCode == 200) {
            this.accountOption = result.data.data;
            this.accountId = result.data.data.filter(
              x => x.typeAccountId == this.database.typeAccount.MainAccount
            )[0].id;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.accountOption = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getActivityType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_ACCOUNTING}activityType/get`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.typeOption = [
              {
                id: "",
                name: this.$i18n.t("All")
              }
            ];
          } else {
            result.data.data.push({
              id: "",
              name: this.$i18n.t("All")
            });
            this.typeOption = result.data.data.filter(x => x.id < 12);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getActivities() {
      this.$vs.loading();
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_ACCOUNTING}blockPay/get`,
        data: {
          status: "",
          start_date: startDate,
          end_date: endDate,
          accountId: this.accountId,
          type: this.type,
          transactionNumber: "",
          itaCode: this.itaCode,
          group: false
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "accept-language": this.$i18n.locale.toUpperCase()
        }
      }).then(
        result => {
          if (result.data.success && result.data.code == 200) {
            this.activitiesList = result.data.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.activitiesList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async updateAccount() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}contact/updateAccount`,
        data: {
          id: this.accountIdEdit,
          name: this.accountName
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupEdit = false;
            await this.getAccounts();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    getAccountName() {
      let _data = this.accountOption.find(x => x.id == this.accountIdEdit);
      this.accountName = _data.name;
    },

    onRowSelected(event) {
      this.description = event.node.data.observation;
      this.popupDetail = true;
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
